<template>
  <div class="font-montserrat top">
    <div class="row py-24 d-flex justify-content-center px-12 oonpay-bg">
      <div class="col-md-8">
        <h4 class="font-semibold text-center text-oonpaytext text-3xl">
          Healthhub Subcategory
        </h4>
      </div>
    </div>

    <div v-if="loading" class="row py-5 justify-content-center">
      <fade-loader :loading="loading" :color="color"></fade-loader>
    </div>

    <div v-else class="container">
      <div class="row justify-content-center pt-5">
        <div class="col-md-5">
          <div class="form-group">
            <label for="h2">Change Category</label>

            <select @change="handleSubcategory" name="" class="form-control" v-model="id">
              <option v-for="item in categories" :key="item.id" :selected="id === item.id ? 'selected' : null"
                :value="item.id">{{ item.fullName }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row px-10 py-5 ">
        <div class="col-md-3" v-for="item in subcategories" :key="item.id">
          <router-link :to="`/purchase-pack/${item.id}`" class="card">

            <img v-if="imgurl !== 'null' " v-lazy="
                `${healthhubbaseUrl}/oonpayhealthhubsetup/${imgurl}?cache=${uniqueParam}`
              " class="card-img-top" alt="Product Image" @load="onLoad" />
            <div v-if="isLoading" class="spinner">
              <img src="@/assets/Spinner.gif" alt="Loading..." />
            </div>

            <div class="card-body text-center">
              <h5 class="card-title">{{ item.fullName.length > 20 ? item.fullName.slice(0, 20) + '...' : item.fullName
                }}</h5>

              <router-link :to="`/purchase-pack/${item.id}`"
                class="py-2 px-4 btn-primary text-white rounded hover:bg-blue-600 active:bg-blue-700 disabled:opacity-50 mt-4 w-full flex items-center justify-center">
                Buy Pack
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-2" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </router-link>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import { ImagebaseUrl } from '../../config';


export default {
  data() {
    return {
      healthhubbaseUrl:ImagebaseUrl,
      id: this.$route.params.id,
      imgurl: localStorage.getItem("imgurl"),
      showPasswordCheck: false,
      showAPIError: false,
      errors: [],
      uniqueParam: Date.now(),
      loading: false,
      color: "#0b4369",
      isLoading: true,
    };
  },

  components: {
    FadeLoader
  },
  computed: {
    ...mapGetters("healthhubclient", [
      "supercategories",
      "categories",
      "subcategories"
    ])
  },

  created() {
    // Listen for the beforeunload event to show the spinner
    window.addEventListener("beforeunload", this.showSpinnerOnReload);
  },
  destroyed() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener("beforeunload", this.showSpinnerOnReload);
  },

  mounted() {
    this.$store.dispatch(
      "healthhubclient/getCategorie",
      localStorage.getItem("paramsId")
    );
    this.$store.dispatch("healthhubclient/getHealthhubSupercategory");
    this.getSubcategories();
  },
  methods: {
    showSpinnerOnReload() {
      // Set showSpinner to true when the page is reloading
      this.loading = true;
    },

    onLoad() {
      this.isLoading = false;
    },

    getSubcategories() {
      this.loading = true;
      this.$store
        .dispatch("healthhubclient/getSubCategorie", this.id)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
          }
        });
    },
    handleSubcategory(e) {
      localStorage.setItem("categoryId", e.target.value);
      this.$store.dispatch("healthhubclient/getSubCategorie", e.target.value);
    }
  }
};
</script>

<style scoped>
.oonpay-bg {
  background: #0c4369;
}

.bg-color {
  background: #f7f4fd;
}

.oonpay-bg {
  background: #0c4369;
}

.bg-color {
  background: #f7f4fd;
}

@media screen and (max-width: 720px) {
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
